<script>
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import {formatCurrency} from '@/utils/formatter'
/**
 * Widget Component
 */
export default {

   setup () {
     const { $get } = useHttp()

 const selectedOrder = ref({})

const getOrder = async () => {
      const {data} = await $get({ url: 'master/dashboard'})
      selectedOrder.value = data
    }

 onMounted(() => {
      getOrder()
     
    })

     return {
      getOrder,
      selectedOrder,
      formatCurrency
    }

  }
 
};
</script>

<template>
  <div class="row">
    
    <!-- begin card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Order</p>
              <h4 class="mb-0">{{selectedOrder.totalorder}}</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- end card -->

    <!-- begin card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Verification</p>
              <h4 class="mb-0">000</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- end card -->
    
    
    <!-- begin card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Unpaid - Outgoing</p>
              <h4 class="mb-0">000</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- end card -->

    <!-- begin card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Outgoing</p>
              <h4 class="mb-0">000</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- end card -->
    
    <!-- begin card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Incoming</p>
              <h4 class="mb-0">000</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- end card -->

    
    <!-- begin card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Unpaid - Incoming</p>
              <h4 class="mb-0">000</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- end card -->

  </div>
  <!-- end row -->
</template>