export function formatCurrency (value = 0) {
  const formatted = new Intl.NumberFormat().format(value)
  return `Rp. ${formatted}`
}

export function formatNumber (value = 0) {
  return new Intl.NumberFormat().format(value)
}

export function formatDatepicker (value) {
  const date = new Date(value)
  return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
}

export function formatDateTimeToDate(value){
  const date = new Date(value)
  const formattedDay = date.getUTCDate().toString().padStart(2, "0")
  const formattedMonth =(date.getUTCMonth() + 1).toString().padStart(2, "0") 
  const formattedYear = date.getUTCFullYear().toString()
  return `${formattedDay}-${formattedMonth}-${formattedYear}`
}

export function formatDateTimeToTime(value){
  const date = new Date(value)
  const formattedHour = date.getUTCHours().toString().padStart(2, "0")
  const formattedMinute = date.getUTCMinutes().toString().padStart(2, "0")
  return `${formattedHour}:${formattedMinute}`
}
