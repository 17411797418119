<script>
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
/**
 * Sources Component
 */
export default {
 setup () {

   const { $get } = useHttp()

 const selectedFlight = ref({})

const getFlight = async () => {
      const {data} = await $get({ url: 'master/dashboard_flight'})
       selectedFlight.value = {...data, sch_departure_datetime : data.sch_departure_date + ' ' + data.sch_departure_time}
      // selectedFlight.value = data
    }

 onMounted(() => {
      getFlight()
     
    })

     return {
      getFlight,
      selectedFlight
    }


 }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-3">Flight News</h4>
      <div>
        <div class="table-responsive mt-4">
          <table class="table table-hover mb-0 table-centered table-nowrap">
            <tbody>
              <tr v-for=" (data, index) in selectedFlight" :key="index">
                <th scope="row">
                 <img :src='data.vnd_logo' width="60" />
                  <h6>{{data.vnd_name}}</h6>
                </th>
                <td>
                  <h6>{{data.sch_flight_no}} : {{data.oriprt_initial}} - {{data.desprt_initial}}</h6>
                  <h6>{{data.sch_departure_datetime}}</h6>
                </td>
                <td>{{data.hsc_remaks}}<br/>{{data.hsc_status_datetime}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center mt-4">
          <a href="#" class="btn btn-primary btn-sm">View more</a>
        </div>
      </div>
    </div>
  </div>
</template>